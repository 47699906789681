import { Component, Prop, Mixins } from "vue-property-decorator";
import { Action } from "vuex-class";
import { isEmpty } from "lodash";
import { Notification, MessageTypes } from "@/interfaces/proccess";
import { TypeLoading } from "@/interfaces/loading";
import { ModifierForm, ModifierLoading, ModifierResource } from "@/models/Modifiers/form";
import { ModifierFromAPI, PayloadModifierDispatch } from "@/interfaces/modifier";

import Overview from "@/views/Admin/Modifiers/overview.vue";
import Alertize from "@/components/Alertize.vue";
import RouteValidationMixin from "@/mixins/RouteValidation";

@Component({
  components: {
    Alertize,
    Overview,
  },
})
export default class ModifierLayout extends Mixins(RouteValidationMixin) {
  @Prop({ type: Boolean, default: true }) readonly showButtonsActions!: boolean;

  @Action("setLoadingData", { namespace: "loading" }) setLoadingData!: (payload?: any) => Promise<void>;
  @Action("showModifier", { namespace: "modifier" }) fetchModifierById!: (
    id: number,
  ) => Promise<ModifierFromAPI | undefined>;

  entity = new ModifierForm();
  resources = new ModifierResource();
  loadings = new ModifierLoading();

  async created() {
    if (this.isRouteEdit) {
      await this.loadModifierData();
    }
  }

  async mounted() {
    await this.loadModifierResources();
  }

  async loadModifierData() {
    try {
      this.setLoadingData(TypeLoading.loading);
      this.entity = await this.getModifierById(this.getRouteId);
    } catch (error) {
      console.error(`[loadModifierData] id: ${this.getRouteId}`);
    } finally {
      this.setLoadingData();
    }
  }

  async loadModifierResources() {
    try {
      this.setResourcesLoadings(true);
      await this.onLoadResources();
    } catch (error) {
      console.error(`[loadModifierResources] id: ${this.getRouteId}`);
    } finally {
      this.setResourcesLoadings(false);
    }
  }

  async onLoadResources() {
    const resourceLoaders = [this.dispatchAdvertisers(), this.dispatchModifierTypes(), this.dispatchModules()];
    const [advertisers, modifierTypes, modules] = await this.loadResources(resourceLoaders);

    this.resources.setResource("advertiser_id", advertisers);
    this.resources.setResource("modifier_type_id", modifierTypes);
    this.resources.setResource("module_id", modules);
  }

  setResourcesLoadings(loading: boolean) {
    this.loadings.setLoading("advertiser_id", loading);
    this.loadings.setLoading("modifier_type_id", loading);
    this.loadings.setLoading("module_id", loading);
  }

  async loadResources(loaders: Promise<any>[]): Promise<any[]> {
    const results = await Promise.allSettled(loaders);
    return results?.map(result => (result.status === "fulfilled" ? result.value : []));
  }

  async getModifierById(id: number) {
    const result: ModifierFromAPI | undefined = await this.fetchModifierById(id);
    if (!result) throw new Error("Modifier not found");
    return result.toModifierForm();
  }

  redirectTo() {
    this.setNotification({ title: "", message: "", type: "" });
    this.$router.push({ name: "ModifiersIndex" });
  }

  setNotification(notification: Notification) {
    return this.$store.dispatch("proccess/setNotification", { ...notification, show: true });
  }

  async handleCreate(parameter: { entity: ModifierForm; isBidModifier: boolean; isDeliveryModifier: boolean }) {
    try {
      this.setLoadingData(TypeLoading.loading);
      const result = await this.dispatchCreateEntity(
        parameter.entity,
        parameter.isBidModifier,
        parameter.isDeliveryModifier,
      );

      if (isEmpty(result)) {
        this.setNotification({
          title: this.$t("title-failed").toString(),
          message: this.$t("failed").toString(),
          btn_text: this.$t(MessageTypes.TRYAGAIN).toString(),
          type: MessageTypes.ERROR,
        });
      } else {
        this.setNotification({
          title: this.$t("title-success").toString(),
          message: this.$t("success").toString(),
          btn_text: this.$t(MessageTypes.CONTINUE).toString(),
          type: MessageTypes.SUCCESS,
          to: "ModifiersIndex",
        });
      }
    } catch (error) {
      console.error("handleCreate", { error });
    } finally {
      this.setLoadingData();
    }
  }

  async handleUpdate(payload: { entity: any; isBidModifier: boolean; isDeliveryModifier: boolean }) {
    try {
      this.setLoadingData(TypeLoading.loading);
      console.debug(`[handleUpdate]`, payload);
      const result = await this.dispatchUpdateEntity(payload.entity, payload.isBidModifier, payload.isDeliveryModifier);
      if (isEmpty(result)) {
        this.setNotification({
          title: this.$t("title-failed").toString(),
          message: this.$t("failed").toString(),
          btn_text: this.$t(MessageTypes.TRYAGAIN).toString(),
          type: MessageTypes.ERROR,
        });
      } else {
        this.setNotification({
          title: this.$t("title-success").toString(),
          message: this.$t("success").toString(),
          btn_text: this.$t(MessageTypes.CONTINUE).toString(),
          type: MessageTypes.SUCCESS,
          to: "ModifiersIndex",
        });
      }
    } catch (error) {
      console.error("handleUpdate", { error: error });
    } finally {
      this.setLoadingData();
    }
  }

  handleCancel() {
    this.$router.push({ name: "ModifiersIndex" });
  }

  async handleDeleteTerms() {
    this.entity.delivery_terms = [];
  }

  async dispatchAdvertisers() {
    return this.$store.dispatch("advertiser/list", {
      filters: { active: true },
      options: { sort: "name", order: "asc" },
    });
  }

  async dispatchModifierTypes() {
    return this.$store.dispatch("modifier/getModifierTypes", {});
  }

  async dispatchModules() {
    return this.$store.dispatch("modifier/getModulesList", {});
  }

  async dispatchCreateEntity(
    entity: ModifierForm,
    isBidModifier: boolean = false,
    isDeliveryModifier: boolean = false,
  ) {
    return this.$store.dispatch(
      "modifier/createModifier",
      new PayloadModifierDispatch({
        modifier: entity,
        isBidModifier,
        isDeliveryModifier,
      }),
    );
  }

  async dispatchUpdateEntity(
    entity: ModifierForm,
    isBidModifier: boolean = false,
    isDeliveryModifier: boolean = false,
  ) {
    return this.$store.dispatch(
      "modifier/updateModifier",
      new PayloadModifierDispatch({
        id: this.getRouteId,
        modifier: entity,
        isBidModifier,
        isDeliveryModifier,
      }),
    );
  }
}
